<style lang="less" scope>
.fit-layout {
  z-index: 2;
  position: relative;
  transform-origin: center center;
  flex-shrink: 0;
}

.layout {
  .lefts {
    position: fixed;
    left: 0;
    top: 0;
    background: black;
    height: 100%;
    z-index: 888;
  }
  .rights {
    position: fixed;
    right: 0;
    top: 0;
    background: black;
    height: 100%;
    z-index: 888;

  }
  .bottoms {
    position: fixed;
    bottom: 0;
    left: 0;
    background: black;
    z-index: 888;
    width: 100%;
  }
  .tops {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    z-index: 888;
    width: 100%;
  }
}
</style>

<template>
  <div class="layout">
    <div class="lefts" :style="`width:${widths}px`"></div>
    <div class="rights" :style="`width:${widths}px`"></div>
    <div class="tops" :style="`height:${heights}px`"></div>
    <div class="bottoms" :style="`height:${heights}px`"></div>
    <div
      class="fit-layout"
      :style="`transform: scale(${x});width:${width}px;height:${height}px`"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import bus from "./utils/bus";
export default {
  name: "fit-layout",
  props: {
    width: Number,
    height: Number,
  },
  data() {
    return {
      widths: 0,
      heights: 0,
      x: 1,
      y: 1,
    };
  },
  methods: {
    setScale() {
      let { offsetWidth, offsetHeight } = document.body;
      let width = this.width;
      let height = this.height;
      let screenRadio = width / height;
      let bodyRadio = offsetWidth / offsetHeight;

      //如果屏幕宽高比例大于需要固定高度
      if (bodyRadio > screenRadio) {
        this.y = offsetHeight / height;
        this.x = this.y;
        this.heights = 0;
        this.widths = (offsetWidth - this.width * this.x) / 2;
        console.log("width", this.widths);
      } else {
        this.x = offsetWidth / width;
        this.y = this.x;

        this.widths = 0;
        this.heights = (offsetHeight - this.height * this.x) / 2;
      }
    },
  },
  mounted() {
    this.setScale();
    window.addEventListener("resize", () => {
      this.setScale();
      setTimeout(() => {
        bus.emit("changeScene", this.x);
      }, 100);

    });
    bus.on("resize", (fn) => {
      fn && fn(this.x);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.setScale);
  },
};
</script>
