/**
 * @description 全局组件
 * @tips 全局扫描 自动注册 {@components} 中所有组件,组件名称字段是name
 */

export default {
    install(app) {
        const ctx = require.context('./', false, /\.vue$/)
        ctx.keys().forEach((item) => {
            const component = ctx(item).default;
            app.component(component.name, component)
        })
    }
}

