<template>
  <fit-layout :width="fitWidth" :height="fitHeight">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
  </fit-layout>

  <!-- <sceneComs></sceneComs> -->
</template>

<script>
import sceneComs from "./../src/components/three-coms";
import bus from "./lib/bus";


export default {
  components: { sceneComs },
  data() {
    return {
      socket: "",
      isFull: false,
    };
  },
  created() {
    this.loadWs();
    window.ns=()=>this.$store.state.isCn=!this.$store.state.isCn
    // window.ondblclick=()=>{
    //   console.log(this.$store.state.isCn)
      
    // }
  },
  computed: {
    fitWidth() {
      if (this.$route.name == "scene4") {
        return 3840;
      } else {
        return 1920;
      }
    },
    fitHeight() {
      if (this.$route.name == "scene4") {
        return 3240;
      } else {
        return 1080;
      }
    },
  },
  methods: {
    loadWs() {
      this.initWebSocket();
    },
    initWebSocket() {
      let wsUrl = process.env.VUE_APP_BASE_WSURL;
      this.socket = new WebSocket(wsUrl);
      this.socket.onmessage = this.wsMessage;
      this.socket.onclose = this.wsClose;
      this.socket.onerror = this.wsError;
    },
    wsClose() {
      this.initWebSocket();
    },
    wsError() {
      this.initWebSocket();
    },
    wsMessage({ data }) {
      console.log("信息")
      let RESS = JSON.parse(data);
      //切换中文
      if(RESS.deactive=='changeLang'){
        this.$store.state.isCn=RESS.value
        return
      }
      if (RESS.deactive == "allScene") {
        this.isFull = !this.isFull;
        if (this.isFull) {
          console.log("全屏")
          if (document.documentElement.RequestFullScreen) {
            document.documentElement.RequestFullScreen();
          }
          //兼容火狐
          console.log(document.documentElement.mozRequestFullScreen);
          if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          }
          //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
          if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen();
          }
          //兼容IE,只能写msRequestFullscreen
          if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
          }
        } else {
          console.log("退出全屏")
          if (document.exitFullScreen) {
            document.exitFullscreen();
          }
          //兼容火狐
          console.log(document.mozExitFullScreen);
          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          }
          //兼容谷歌等
          if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
          //兼容IE
          if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }
      try {
        // console.log("发送信息");
        bus.emit("sod", data);

        // bus.
      } catch (e) {
        console.log("错误捕获", e);
      }
    },
  },
  unmounted() {
    this.socket.close();
   
  },
};
</script>
<style lang="less">
html,
body {
  margin: 0px !important;
  overflow: hidden;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxsss {
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 1000;
}

.boxsss-right {
  position: absolute;
  right: 0px;
  top: 0;
  // width: 672px;
  z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1.5s ease;
}

.fade-enter-from {
  left: -800px;
}

.fade-enter-to {
  left: 0;
}

.fade-leave-from {
  left: 0px;
}

.fade-leave-to {
  left: -800px;
}

.rightFade-enter-active,
.rightFade-leave-active {
  transition: all 1.5s ease;
}

.rightFade-enter-from {
  right: -800px;
}

.rightFade-enter-to {
  right: 0;
}

.rightFade-leave-from {
  right: 0px;
}

.rightFade-leave-to {
  right: -800px;
}
</style>
