<template>
  <div class="three-coms" ref="three-coms" onselectstart="return false;">
    <div
      :class="'coms-sprite-name-btn ' + 'sprite-' + value.name + '-coms-btn '"
      v-for="(value, key, index) in pointMap"
      :key="index"
      v-show="value.isShow"
    >
      <div class="icon-bg">
        <div class="icon">
          <img :src="value.imgSrc" alt="" />
        </div>
      </div>
    </div>

    <div
      :class="'coms-sprite-name-img ' + 'sprite-' + value.name + '-coms-img'"
      v-for="(value, key, index) in pointMap"
      :key="index"
    >
      <div class="left-img">
        <div class="icon">
          <img :src="value.imgSrc" alt="" />
        </div>
      </div>
      <div class="text show-anime">{{ value.uiName }}</div>
    </div>
  </div>
</template>

<script>
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Change from "./Change";
import { RunScene } from "run-scene-v2";
import bus from "./../utils/bus";
import { spriteNameArray } from "./const";
export default {
  name: "sceneComs",
  data() {
    return {
      // pointMap: spriteNameArray,
      pointMap: {
        WE产品家族: {
          isShow: false,
          name: "云点位",
          uiName: "WE产品家族",
          imgSrc: require("@/assets/common/upload.png"),
        },
        CBD商业区: {
          isShow: false,
          name: "CBD点位",
          uiName: "建筑光伏一体化",
          imgSrc: require("@/assets/common/cbd.png"),
        },
        综合能源智慧园区: {
          isShow: false,
          name: "综合智慧园区点位",
          uiName: "综合能源智慧园区",
          imgSrc: require("@/assets/common/yuanqu.png"),
        },
        虚拟电厂: {
          isShow: true,
          name: "虚拟电厂点位",
          uiName: "虚拟电厂",
          imgSrc: require("@/assets/common/ele.png"),
        },
        整县推进: {
          isShow: false,
          name: "整县推进点位",
          uiName: "整县推进",
          imgSrc: require("@/assets/common/zhengxian.png"),
        },
        // 双碳平台: {
        //   isShow: false,
        //   name: "3060双碳点位",
        //   uiName: "双碳平台",
        //   imgSrc: require("@/assets/common/co2.png"),
        // },
      },
    };
  },
  mounted() {
    document.getElementById("app").appendChild(this.$refs["three-coms"]);
    // 加载场景
    this.loadScene();
  },
  methods: {
    // 加载场景
    loadScene() {
      scene = new RunScene({
        msg: {
          // show: true,
        },
        // showFps: false,
        coverSameId: true,
        instanceClone: false,
        render3: true,
        render2: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
        },
      })
        .load({
          // path: "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202212132009257649071001202284",
          path: process.env.VUE_APP_BASE_ASSETS+"assets/scene4.glb",
          // path: "https://test2-1303915342.cos.ap-shanghai.myqcloud.com/goodwe/scene4.glb",
          dom: this.$refs["three-coms"],
        })
      // 实例化场景
      sceneChange = new Change(scene, this.onDone);
      window.sceneChange4 = scene;
    },
    // 加载完回调
    onDone() {
      console.log("场景加载完毕~");

      /**
       * Interface description: 展示对应的区域 线框接口
       *          params1 <string>  对应线框名称  "虚拟电厂","综合智慧园区","整县推进","3060双碳","CBD","云"
       *          params2 <boolean> 是否显示
       */
      // bus.emit("isShowWireFrame", "虚拟电厂", true);
      // bus.emit("isShowWireFrame", "综合智慧园区", true);

      // bus.emit("three-coms-isShowAllSprite", true);
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
  },
  // 场景自带销毁
  unmounted() {
    sceneChange && sceneChange.dispose();
    sceneChange = null;
  },
};

// 导出场景 资源
export { sceneChange, scene };
</script>

<style lang="less" scoped>
// 场景
.three-coms {
  // width: 3840px;
  // height: 3240px;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 311111;
}

.coms-sprite-name-btn {
  position: absolute;
  z-index: 2;
  opacity: 0;
  width: 72px;
  height: 72px;
  background: linear-gradient(270deg, #1106dd 0%, #e52c2c 100%);
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 8px 0px rgba(238, 251, 255, 0.84);
    border-radius: 50%;
    .icon {
      width: 36px;
      height: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.show-anime {
  animation: widths 1s ease forwards;
}
@keyframes widths {
  from {
    max-width: 10px;
    overflow: hidden;
  }
  to {
    max-width: 280px;
    overflow: hidden;
  }
}
.coms-sprite-name-img {
  background: rgba(255, 255, 255, 0.4);
  height: 54px;
  background: linear-gradient(270deg, #1106dd 0%, #e52c2c 100%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  padding: 6px;
  // width:0;
  // height:0;
  overflow: hidden;
  .left-img {
    width: 54px;
    height: 54px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 8px 0px rgba(238, 251, 255, 0.84);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 36px;
      height: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .text {
    margin: 0 16px;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
    color: #ffffff;
    font-size: 18px;
  }
}

.three-coms .btn {
  position: absolute;
  z-index: 2;
}

.three-coms .show {
  opacity: 1 !important;
}

.three-coms .none {
  opacity: 0 !important;
}

.three-coms .block {
  display: block !important;
}
</style>
