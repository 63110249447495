import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from "./components/index"
// 生产环境下将打印去除
if (process.env.NODE_ENV !== 'development') {
    console.log = function () { }
    console.error = function () { }
}
createApp(App).use(store).use(components).use(router).mixin({
    computed:{
        isCn(){
            return this.$store.state.isCn
        }
    }
}).mount('#app')




