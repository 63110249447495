// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import bus from "./../utils/bus";
import * as THREE from "three";

// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  // 最后一帧加载回调
  t.runScene.on("loaded", async () => {

    this.events = new Events();

    t.runScene.cameraEx.setTemp('垂直镜头', 0.1)

    this.tool = new Tool();

    this.selectedStatus = new SelectedStatus();

    this.selectedStatus.init();

    this.sprite = new Sprite();

    this.sprite.init();

    this.anima = new Anima();

    this.anima.init();


    // 基本配置
    (() => {
      t.runScene.script.playAll();

      const resizeCanvas = function (x) {

        let map = t.runScene.assetsEx.engineDom.getBoundingClientRect();
        t.runScene.setSize(map.width, map.height);


      };

      bus.emit("resize", resizeCanvas);
      bus.on("changeScene", (x) => {
        setTimeout(() => {
          resizeCanvas(x);
        }, 100);
      });
      t.runScene.assetsEx.controls.autoRotate = false

      // 加载回调
      t.onDone();
    })();
  });

  // 销毁
  this.dispose = () => {
    runScene.dispose();
  }
}

// 动画
class Anima {

  init() {
    this.runAnima()
  }

  runAnima() {
    //播放动画
    t.runScene.anima.play(
      t.runScene.modelEx.getModel("风车动画"),
      "Take 001",
      {
        loop: true,
      }
    );
    t.runScene.anima.play(t.runScene.modelEx.getModel("船动画"), "Take 001", {
      loop: true,
    });

  }
}


class Sprite {
  // 精灵图 表
  spriteModelMap = {};

  init() {
    // 获取 点位 映射表
    this.getSpriteModelMap();

    // 添加dom
    this.addDom();

    // 显示 对应 区域的信息看板
    bus.on("three-coms-isShowSprite", this.isShowSprite.bind(this));

    bus.on("three-coms-isShowAllSprite", this.isShowAllSprite.bind(this));
  }
  // 获取 点位 映射表
  getSpriteModelMap() {
    const dianwei = t.runScene.modelEx.getModel("垂直点位");
    dianwei.children.map((childModel) => {
      const name = childModel.name;
      this.spriteModelMap[name] = {};
      this.spriteModelMap[name]["model"] = {};
      this.spriteModelMap[name]["model"] = childModel;
    });
  }

  // 添加dom
  addDom() {
    let { domTo2Dui, getMacro } = Utils;
    Object.keys(this.spriteModelMap).map((name) => {
      let btnDom = document.querySelector(`.sprite-${name}-coms-btn`);
      this.spriteModelMap[name]["childModel-btn"] = domTo2Dui(btnDom);
      this.spriteModelMap[name]["childModel-btn"].name = `${name}-coms-btn`;
      this.spriteModelMap[name]["model"].add(
        this.spriteModelMap[name]["childModel-btn"]
      );
      getMacro(() => {
        btnDom.classList.add("show");
      }, 500);

      let imgDom = document.querySelector(`.sprite-${name}-coms-img`);
      this.spriteModelMap[name]["childModel-img"] = domTo2Dui(imgDom);
      this.spriteModelMap[name]["childModel-img"].name = `${name}-coms-img`;
      this.spriteModelMap[name]["model"].add(
        this.spriteModelMap[name]["childModel-img"]
      );
      this.spriteModelMap[name]["childModel-img"].visible = false;
      getMacro(() => {
        imgDom.classList.add("show");
      }, 500);
    });
  }

  // 是否 显示精灵图
  isShowSprite(name, isShow) {
    const newName = `${name}点位`;
    const modelBtn = this.spriteModelMap[newName]["childModel-btn"];
    const modelImg = this.spriteModelMap[newName]["childModel-img"];
    if (!modelBtn || !modelImg) return;
    modelImg.visible = isShow;
    modelBtn.visible = !isShow;
  }

  // 一次性显示 和 关闭 所有标签
  isShowAllSprite(isShow) {
    Object.keys(this.spriteModelMap).map((name) => {
      const modelBtn = this.spriteModelMap[name]["childModel-btn"];
      const modelImg = this.spriteModelMap[name]["childModel-img"];
      if (!modelBtn || !modelImg) return;
      modelImg.visible = isShow;
      modelBtn.visible = !isShow;
    });
  }
}

// 选中 状态
class SelectedStatus {
  // 上个 状态 名称
  oldName = null;

  // 线框映射表
  wireFrameMap = {};

  // 初始化
  init() {
    // 获取 线框 模型
    this.getWireFrameMap();
    // 显示 对应 线框 接口
    bus.on("isShowWireFrame", this.isShowWireFrame.bind(this));
  }

  // 获取 线框模型
  getWireFrameMap() {
    const wireFrame = t.runScene.modelEx.getModel("线框");
    wireFrame.children.map((childName) => {
      const name = childName.name;
      this.wireFrameMap[name] = childName;
    });
  }

  isShowWireFrame(name, isShow = true) {
    if (!this.oldName) {
      if (!this.wireFrameMap[name]) return;

      this.wireFrameMap[name].visible = isShow;
      t.sprite.isShowSprite(name, isShow);
    } else {
      this.wireFrameMap[this.oldName].visible = !isShow;
      t.sprite.isShowSprite(this.oldName, !isShow);

      t.sprite.isShowSprite(name, isShow);
      this.wireFrameMap[name].visible = isShow;
    }
    // 刷新 当前 名称
    this.oldName = name;
  }
}

// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn = () => { }) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };

  closeAnimaAtStart = { enterAnima: "", cameraAnima: "" };

  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );
  }

  cameraAnima(position, time = 1) {
    this.closeAnimaAtStart.cameraAnimaFn = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      }
    );
  }

  triggerClick = (model) => {
    if (!model) return;
    const log = `cx:${camera.position.x}, cy:${camera.position.y}, cz:${camera.position.z}, tx:${controls.target.x}, ty:${controls.target.y}, tz:${controls.target.z} `;
    console.log("点位:", log);
  };

  // 清除动画
  controlStart = () => { };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}

export default Change;
