import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/scene-1',
  },
  // {
  //   path: '/controls',
  //   name: 'controls',
  //   component: () => import('@/views/controls')
  // },
  {
    path: '/scene-1',
    name: 'scene1',
    component: () => import('@/views/scene-1'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/scene-2',
    name: 'scene2',
    component: () => import('@/views/scene-2'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/scene-3',
    name: 'scene3',
    component: () => import('@/views/scene-3'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/scene-4',
    name: 'scene4',
    component: () => import('@/views/scene-4'),
    meta: {
      keepAlive: true,
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    window.sceneChange1 && window.sceneChange1.setSize(document.body.offsetWidth, document.body.offsetHeight)
    window.sceneChange2 && window.sceneChange2.setSize(document.body.offsetWidth, document.body.offsetHeight)
    window.sceneChange3 && window.sceneChange3.setSize(document.body.offsetWidth, document.body.offsetHeight)
    window.sceneChange4 && window.sceneChange4.setSize(document.body.offsetWidth, document.body.offsetHeight)
  }, 100);
  next()
})
export default router