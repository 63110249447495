<template>
  <div id="animation" style="width: 20%; height: 20%"></div>
</template>

<script>
import lottie from "lottie-web";
import animationData from "../assets/lodding2.json";
export default {
  name: "lottie-web",
  mounted() {
    console.log(animationData);
    let animationDom = document.getElementById("animation"); //获取渲染的dom元素
    if (animationDom) {
      let fireworkLeft = lottie.loadAnimation({
        container: animationDom,
        renderer: "svg",
        name: "fireworkLeft",
        loop: true, //循环
        autoplay: true, //自动播放
        // assetsPath: "images/",
        // animationData: animationData,//动画数据
        animationData: JSON.parse(JSON.stringify(animationData)),
      });
      fireworkLeft.addEventListener("complete", () => {
        fireworkLeft.destroy();
      });
    }
  },
};
</script>
