<template>
  <transition name="fade">
    <div class="customBox" :style="`width:${boxWidth}px;height:${boxHeight}px`">
      <div class="box-content">
        <slot></slot>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div class="left-bg"></div>
  </transition>
</template>
<script>
export default {
  name: "custom-box",
  data() {
    return {};
  },
  props: {
    boxWidth: {
      type: String,
      default: "672",
    },

    boxHeight: {
      type: String,
      default: "1016",
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.customBox::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background: rgba(255, 255, 255, 0.4);
  // backdrop-filter: blur(8px);
  // border-radius: 32px;
  // box-shadow: inset 0 0 2px #d9d9d9;
}

// .customBox::after
.customBox {
  position: absolute;
  top: 32px;
  left: 32px;
  background: inherit;
  z-index: 1000;

  .box-content {
    // width: 100%;
    // height: 100%;
    width: 672px;
   background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  border-radius: 32px;
  box-shadow: inset 0 0 2px #d9d9d9;
    position: absolute;
    overflow: visible;
  }
}

.left-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 318px;
  height: 292px;
  background-image: url("../assets/common/left-bg.png");
  background-size: 100%;
}
</style>
