<template>
  <div class="customBox">
    <div class="box-bg">
      <div class="right-content">
        <slot></slot>
      </div>
    </div>
  </div>
  <div class="right-bg"></div>
</template>
<script>
export default {
  name: "custom-box-right",
  data() {
    return {};
  },
  props: {
    boxWidth: {
      type: String,
      default: "672",
    },

    boxHeight: {
      type: String,
      default: "750",
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style>

.right-content img{
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  border-radius: 32px;
  box-shadow: inset 0 0 2px #d9d9d9;
}
</style>
<style scoped lang="less">
.customBox {
  position: absolute;
  top: 32px;
  right: 32px;
  background: inherit;
  z-index: 1000;
  overflow: visible;
  width: 672px;
  height: auto;

}
.box-bg::before {
  content: "";
  width: 672px;
  overflow: visible;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  border-radius: 32px;
  box-shadow: inset 0 0 2px #d9d9d9;
}

.box-bg {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.right-content {
  width: 100%;
  height: 100px;
  position: absolute;
}
.right-bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 318px;
  height: 292px;
  background-image: url("../assets/common/right-bg.png");
  background-size: 100% 100%;
}
</style>
